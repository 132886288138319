<template>
 
 <div class="trb" v-if="isTranslated" @click="revert">
  <i class="icon-translate"></i>
  {{ $t('universal_translate_original') }}
  </div>
 <div class="trb" v-else @click="translate" :class="{'gloading': isLoading}">
  <i class="icon-translate"></i>
  {{ $t('universal_translate') }}
  </div>
  
</template>

<script setup>
 

 const { $translate } = useNuxtApp();
//  :el="refCommentText"
// :text="props.data.text"
// :id="props.data.id"
// :type="'comment'"
// :locale="$i18n.locale"

const props = defineProps({
  el: {
    type: Object,
    required: true,
  },
  text: {
    type: String,
    required: true
  },
  id: {
    type: Number,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  locale: {
    type: String,
    required: true
  }
});
const isLoading = ref(false);
const isTranslated = ref(false);

const revert = () => {
  props.el.innerHTML = props.text;
  isTranslated.value = false;
}

const translate = () => {
  isLoading.value = true;
  $translate(props.el, props.id, props.type, props.locale, () => {
    isLoading.value = false;
    isTranslated.value = true;
  })
};
 
</script>

<style scoped>
 .trb{
  position: relative;
  width: fit-content;
  cursor: pointer;
 }

</style>
